import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { accountsConfig } from '../../../../../axiosConfig'
import { useNavigate } from 'react-router-dom'

export const Card = ({ item }) => {
    const navigate = useNavigate()
    return (
        <CardCom onClick={() => navigate(`/blogs/${item?.id}`)}>
            <ImageContainer backgroundImage={item?.image || ""} />
            <BlogTitle>
                {item?.title}
            </BlogTitle>
        </CardCom>
    )
}

export const DestinationBlogs = ({destination}) => {
    const [data, setData] = useState([])
    useEffect(() => {
        accountsConfig
            .get(`general/list-blogs/?destination=${destination}`,)
            .then((res) => {
                if (res.data.app_data.StatusCode == 6000) {
                    setData(res.data.app_data.data);
                } else {
                    setData([]);
                }
            });
    }, []);
    return (
        <>
            {data && data.length > 0 && (<Section>
                <Wrapper className='wrapper'>
                    <Title>Blogs</Title>
                    <CardContainer>
                        {data.map((item, index) => (
                            <Card key={index} item={item} />
                        ))}
                    </CardContainer>
                </Wrapper>
            </Section>)}
        </>
    )
}

const Section = styled.div`

@media (max-width:980px){
    margin-bottom: 50px;
}
`

const Wrapper = styled.div``

const Title = styled.h1`
    font-size: 30px;
    color: #1f93b0;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-bold";
    padding: 50px 0;
    @media (max-width:1023px){
        padding: 30px 0;
        font-size: 32px;
    }
`
const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;

    @media (max-width:1023px){
        grid-template-columns: repeat(3,1fr);
        gap: 30px;
    }
    @media (max-width:768px){
        grid-template-columns: repeat(2,1fr);
        gap: 30px;
    }
    @media (max-width:480px){
        grid-template-columns: repeat(1,1fr);
        gap: 30px;
    }
    
`

const CardCom = styled.div`
    cursor: pointer;
`

const ImageContainer = styled.div`
    background-image: url(${props => props.backgroundImage});
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
`;

const BlogTitle = styled.p`
    font-size: 16px;
    margin-top: 10px;
    color: #1f93b0;
    font-style: normal;
    line-height: normal;
    text-align: center;
    font-family: "ubuntu-regular";
`