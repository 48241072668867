import React, { useState } from 'react';
import styled from 'styled-components';
import Course from './Searchbar/Course';
import ScholarShips from './Searchbar/ScholarShips';
import Universities from './Searchbar/Universities';
import Intake from './Searchbar/Intake';
import GuidMe from './Searchbar/GuidMe';
import { useLocation } from 'react-router-dom';

function SearchBar() {
  const location = useLocation();
  const isOfferPage = location.pathname === '/offers';
  const [selected, setSelected] = useState('county');
  return (
    <Container>
      <SearchMainBar isOfferPage={isOfferPage}>
        <Tabs isOfferPage={isOfferPage}>
          <Titles isOfferPage={isOfferPage} className={selected === 'county' ? 'selected' : ''} onClick={() => setSelected('county')}>Countries</Titles>
          <Titles isOfferPage={isOfferPage} className={selected === 'intake' ? 'selected' : ''} onClick={() => setSelected('intake')}>Intake</Titles>
          <Titles isOfferPage={isOfferPage} className={selected === 'universities' ? 'selected' : ''} onClick={() => setSelected('universities')}>Universities</Titles>
          <Titles isOfferPage={isOfferPage} className={selected === 'scholarships' ? 'selected' : ''} onClick={() => setSelected('scholarships')}>Scholarships</Titles>
          <Titles isOfferPage={isOfferPage} className={selected === 'guid-me' ? 'selected' : ''} onClick={() => setSelected('guid-me')}>Guide me</Titles>
        </Tabs>
        <ShowContent isOfferPage={isOfferPage}>
          {selected === 'county' && <Course />}
          {selected === 'scholarships' && <ScholarShips />}
          {selected === 'universities' && <Universities />}
          {selected === 'intake' && <Intake />}
          {selected === 'guid-me' && <GuidMe />}
        </ShowContent>
      </SearchMainBar>
    </Container>
  );
}

export default SearchBar;

const Container = styled.div`
  width: 60%;
  margin: auto;
  padding: 60px 0 5px 0;
  background: transparent;
  @media (max-width:1440px){
    width: 80%;
  }
  @media (max-width:1023px){
    width: 90%;
  }
`;

const SearchMainBar = styled.div` 
  margin-top: -123px;
  box-shadow:  ${props => props.isOfferPage ? 'none' : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'};
  border-radius:  ${props => props.isOfferPage ? 'none' : '24px'};
`;

const ShowContent = styled.div`
   background: #fff;
   border-radius: 24px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.isOfferPage ? 'transparent' : 'rgb(249, 250, 251)'};
  gap: 30px;
  padding: 0 40px;
  border-radius: 24px 24px 0px 0px;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom:  ${props => props.isOfferPage ? '10px' : '0px'};
  &::-webkit-scrollbar {
    display: ${props => props.isOfferPage ? 'none' : 'block'};
  }
  scrollbar-width: ${props => props.isOfferPage ? 'none' : 'auto'};
  @media (max-width:767px){
    gap: 10px;
  }
`;

const Titles = styled.div`
  cursor: pointer;
  font-family: 'ubuntu-medium';
  font-size: 18px;
  padding: ${props => props.isOfferPage ? '10px 10px' : '20px 10px'};
  border-bottom: 2px solid transparent;
  opacity: ${props => props.isOfferPage ? '100%' : '80%'};
  transition: color 0.3s ease-in, border-bottom 0.3s ease-in;
  color:  ${props => props.isOfferPage ? 'white' : 'black'};

  &:hover {
    color: ${props => props.isOfferPage ? 'white' : 'var(--headerColor)'};
    border-bottom: 2px solid var(--headerColor);
    border-bottom: ${props => props.isOfferPage ? ' 2px solid white' : ' 2px solid var(--headerColor)'};
  }

  &.selected {
    color: ${props => props.isOfferPage ? 'white' : 'var(--headerColor)'};
    border-bottom:  ${props => props.isOfferPage ? '2px solid white' : '2px solid var(--headerColor)'};
  }
  @media (max-width:767px){
    font-weight: bold;
    font-size: 16px;
  }
`;

